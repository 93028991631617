import Vue from 'vue'
import LoggingPage from '../../components/LoggingPage';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false;

Vue.use(ElementUI);

new Vue({
    render: h => h(LoggingPage),
}).$mount('#logging1');
