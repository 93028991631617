<template>
    <div id="logging" class="logging-div">
        <pre>Logging.vue</pre>
        <!--
        https://element.eleme.io/#/en-US/component/message-box
        -->
        <el-button type="text" @click="open5">Click to open Message Box</el-button>
    </div>
</template>

<script>
    export default {
        name: 'LoggingPage',
        methods: {
            firealert: function () {
                console.log("logging is here!");
            },
            open5() {
                this.$alert('<strong>This is <i>HTML</i> string</strong>', 'HTML String', {
                    dangerouslyUseHTMLString: true
                });
            }
        },
        beforeMount(){
            this.firealert()
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .super-table {
        background-color: #4FB4D7;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .demo {
        margin: 2em;
        padding: 2em;
        box-shadow: 1px 1px 4px #333;
        max-width: 200px;
        background-color: #fff;
        button {
            margin: 0 2px;
        }
    }
    table {
        border: 3px solid red;
    }

    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }
</style>
